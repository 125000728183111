import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const tableRows = [
  {
    product: "Initial Physio Consultation",
    description: "60mins",
    price: "$150",
  },
  {
    product: "Initial Physio Consultation",
    description: "45mins",
    price: "$140",
  },
  {
    product: "Standard Physio Consultation",
    description: "30mins",
    price: "$110",
  },
  {
    product: "Standard Physio Consultation",
    description: "45mins",
    price: "$120",
  },
  {
    product: "Exercise Scientist Consultation",
    description: "1:1 training 30min",
    price: "$60",
  },
  {
    product: "Exercise Scientist Consultation",
    description: "1:1 training 60min",
    price: "$80",
  },
  {
    product: "Exercise Scientist Group training",
    description: "60min",
    price: "Please enquire",
  },
];

const ServiceTable = () => {
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Product/Service</TableCell>
          <TableCell>Description</TableCell>
          <TableCell align="right">Price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableRows.map((row) => (
          <TableRow key={`${row.product}-${row.description}`}>
            <TableCell component="th" scope="row">
              {row.product}
            </TableCell>
            <TableCell align="left">{row.description}</TableCell>
            <TableCell align="right">{row.price}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ServiceTable;
